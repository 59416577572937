/* eslint-disable react/jsx-pascal-case */
import { graphql } from "gatsby"
import { jsx } from 'theme-ui';
import PostsList from "../components/PostsList";
import Layout from '../layout'


/** @jsx jsx */


export const pageQuery = graphql`
  query blogIndex {
    allMdx(
      filter: {
        frontmatter: {
          template: {eq: "post"},
          draft: { ne: true }
        }
      }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

const Posts = ({ data: { allMdx: { edges: posts } } }) => (
  <Layout>
    <PostsList posts={posts} />
  </Layout>
)

export default Posts;